<template>
  <!-- 新 - 首页 - 课程中心 -->
  <div class="inductrialInjuryPrevention gotop">
    <!-- 顶部导航 -->
    <headPageNew activeName="7"></headPageNew>
    <!-- banner图 -->
    <div class="shell_1"></div>
    <!-- 服务优势 -->
    <div class="shell_2">
      <div class="shell">
        <div class="s_1">工伤预防体系建设</div>
        <img class="s_2" src="../../assets/loginIndexNew/inductrialInjuryPrevention_10.png" alt="">
      </div>
    </div>
    <!-- 推荐课程 -->
    <div class="shell_3">
      <div class="shell">
        <div class="s_1">推荐课程</div>
        <div class="s_2">
          <span :class="item.courseTypeId == courseDatas.courseTypeId ? 'ts_1' : ''"
            v-for="(item, index) in courseDatas.classification" :key="index" @click="clickOnClassification(item)">{{
              item.name }}</span>
        </div>
        <div class="s_3">
          <div v-for="(item, index) in courseDatas.courseList" :key="index" @click="courseDetail(item)">
            <img class="s_3_1" :src="item.logo" alt="">
            <div class="s_3_2">{{ item.courseName }}</div>
          </div>
        </div>
        <!-- <el-pagination style="text-align: center; padding: 30px 20px 10px;" background
          layout="total, sizes,prev, pager, next,jumper" @size-change="handleSizeChange"
          @current-change="handleCurrentChange" popper-class="popper" :popper-append-to-body="false"
          :current-page="courseDatas.fenYe.currentPage" :page-sizes="[10]" :page-size="courseDatas.fenYe.size"
          :total="courseDatas.fenYe.total" v-show="courseDatas.fenYe.total > 0"></el-pagination> -->
      </div>
    </div>
    <!-- 专家智库 -->
    <div class="shell_4">
      <div class="shell">
        <div class="s_1">专家智库</div>
        <img class="s_2" src="../../assets/loginIndexNew/inductrialInjuryPrevention_2.png" alt="">
      </div>
    </div>
    <!-- 解决方案 -->
    <div class="shell_5">
      <div class="shell">
        <div class="s_1">解决方案</div>
        <div class="s_2">
          <div class="s_2_1">
            <img class="s_2_1_1" src="../../assets/loginIndexNew/inductrialInjuryPrevention_4.png" alt="">
            <div class="s_2_1_2">重点（标杆）企业打造方案</div>
          </div>
          <div class="s_2_1">
            <img class="s_2_1_1" src="../../assets/loginIndexNew/inductrialInjuryPrevention_4.png" alt="">
            <div class="s_2_1_2">宣传策划方案</div>
          </div>
          <div class="s_2_1">
            <img class="s_2_1_1" src="../../assets/loginIndexNew/inductrialInjuryPrevention_4.png" alt="">
            <div class="s_2_1_2">线上线下培训方案</div>
          </div>
          <div class="s_2_1">
            <img class="s_2_1_1" src="../../assets/loginIndexNew/inductrialInjuryPrevention_4.png" alt="">
            <div class="s_2_1_2">信息化建设方案</div>
          </div>
          <div class="s_2_1">
            <img class="s_2_1_1" src="../../assets/loginIndexNew/inductrialInjuryPrevention_4.png" alt="">
            <div class="s_2_1_2">实施效果评估方案</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 报告模板库  -->
    <div class="shell_6">
      <div class="shell">
        <div class="s_1">报告模板</div>
        <div class="s_2">
          <div class="s_2_1" v-for="(item, index) in reportTemplateLibrary" :key="index">
            <img class="s_2_1_1" src="../../assets/loginIndexNew/inductrialInjuryPrevention_7.png" alt=""
              v-if="item.type == 1">
            <img class="s_2_1_1" src="../../assets/loginIndexNew/inductrialInjuryPrevention_8.png" alt=""
              v-if="item.type == 2">
            <img class="s_2_1_1" src="../../assets/loginIndexNew/inductrialInjuryPrevention_9.png" alt=""
              v-if="item.type == 3">
            <div class="s_2_1_2 cc">{{ item.name1 }}</div>
            <div class="s_2_1_2">{{ item.name2 }}</div>
            <div class="s_2_1_2">{{ item.name3 }}</div>
            <el-button class="s_2_1_3" type="primary" plain round size="small" icon="el-icon-right">下载</el-button>
          </div>
        </div>
      </div>
    </div>
    <bottomPage />
    <hz />
  </div>
</template>
<script>
import headPageNew from "../wrongAuthenticatePage/headPageNew.vue";
import bottomPage from "./bottomPage.vue";
import hz from "../wrongAuthenticatePage/hz.vue";
export default {
  name: "wrongAuthenticatePage_inductrialInjuryPrevention",
  components: {
    headPageNew,
    bottomPage,
    hz
  },
  data() {
    return {
      // 课程数据
      courseDatas: {
        // 分类id
        courseTypeId: "",
        // 分类
        classification: [],
        // 课程
        courseList: [],
        // 分页
        fenYe: {
          currentPage: 1, // 当前页数
          size: 10,
          total: 0, // 共多少页
        },
      },
      // 报告模板库
      reportTemplateLibrary: [{
        type: 1,
        name1: '2022年XXX省',
        name2: '工伤预防数据分析报告',
        name3: ''
      }, {
        type: 2,
        name1: '2022年XXX市 危险化学品',
        name2: '',
        name3: '效果评估报告'
      }, {
        type: 3,
        name1: '2021年XXX市 冶金行业',
        name2: '',
        name3: '效果评估报告'
      }, {
        type: 2,
        name1: '2023年XXX市',
        name2: '建筑业、制造业、服务业',
        name3: '效果评估报告'
      }, {
        type: 2,
        name1: '2021年XXX市',
        name2: '建筑安装业、派遣业',
        name3: '效果评估报告'
      },]
    };
  },
  created() {
    this.getFtype();
  },
  computed: {},
  methods: {
    // 获取课程分类
    getFtype() {
      this.$post("/homepage/gsyf/courseCategory", {}, 3000, false, 2).then((res) => {
        this.courseDatas.classification = [...[{ name: "全部", courseTypeId: "" }], ...res.data];
        this.courseDatas.courseTypeId = this.courseDatas.classification[0].courseTypeId;
        this.getListData(this.courseDatas.fenYe.currentPage, this.courseDatas.fenYe.size);
      });
    },
    // 按课程分类 - 获取课程列表
    getListData(page, pagesize) {
      let data = {
        pageNum: page,
        pageSize: pagesize,
      };
      if (this.courseDatas.courseTypeId) {
        data.courseType = this.courseDatas.courseTypeId;
      }
      this.$post("/homepage/gsyf/course/all", data, 3000, false, 2).then((res) => {
        this.courseDatas.fenYe.total = res.data.total;
        this.courseDatas.courseList = res.data.list || [];
      });
    },
    // 点击分类
    clickOnClassification(item) {
      this.courseDatas.courseTypeId = item.courseTypeId;
      this.fenYe = this.$options.data().fenYe;
      this.getListData(this.courseDatas.fenYe.currentPage, this.courseDatas.fenYe.size);
    },
    // 每页多少条
    handleSizeChange(val) {
      this.courseDatas.fenYe.size = val;
      this.courseDatas.fenYe.currentPage = 1;
      this.getListData(this.courseDatas.fenYe.currentPage, this.courseDatas.fenYe.size);
    },
    // 当前页数
    handleCurrentChange(val) {
      this.courseDatas.fenYe.currentPage = val;
      this.getListData(this.courseDatas.fenYe.currentPage, this.courseDatas.fenYe.size);
    },
    // 记录埋点
    recordBuriedPoints(topNum, contentName, contentId, positionCode) {
      let gatherInfoParams = {
        contentId,// 内容的系统id
        contentName,// 内容名称
        moduleCode: 'COURSE',
        /* 模块标识

          HOME - 首页,
          COURSE - 课程推广页,
          DIGITAL_SERVICE - 数字服务,
          BUSINESS_COOPERATION - 商务合作

        */
        positionCode,
        /* 位置标识
          【PC_HR - HOME】
            NAVIGATION_BAR - 导航栏 （首页/课程资源/数字服务/商务合作/关于晟融/登录）
            INTELLIGENT_TOOLS - 智能化工具
            HR_PATTERN  - 人力资源新模式]

          【PC_HR - COURSE】
            CATEGORY - 分类
            COURSE - 课程
        */
        topNum// 顺序编号
      }
      console.log(gatherInfoParams)
      this.$gatherInfo(gatherInfoParams)
    },
    // 跳转详情
    courseDetail(item) {
      this.$router.push({
        path: "/wrongAuthenticatePage/courseDetail",
        query: {
          courseId: item.courseId,
          courseType: item.courseType,
        },
      });
    },
    // 转换10000-万
    tranNumber(num, point) {
      let numStr = num.toString();
      // 一万以内直接返回
      if (numStr.length < 5) {
        return numStr;
      } else if (numStr.length > 4) {
        let decimal = numStr.substring(
          numStr.length - 4,
          numStr.length - 4 + point
        );
        return parseFloat(parseInt(num / 10000) + "." + decimal) + "万";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.ts_1 {
  color: #2878FF !important;
}

.inductrialInjuryPrevention {
  margin-top: 62px;
  background-color: #F9FAFC;
  height: 100%;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 0 !important
  }

  .shell_1 {
    height: 300px;
    background-size: 100% 100%;
    background-position: center;
    background-image: url("../../assets/loginIndexNew/inductrialInjuryPrevention_1.png");
  }

  .shell_2 {
    width: 100%;
    background: #fff;
    overflow: hidden;

    .shell {
      width: 1440px;
      margin: auto;
      padding: 40px 0;

      .s_1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 40px;
        font-weight: 600;
        color: #333333;
      }

      .s_2 {
        width: 100%;
      }
    }
  }

  .shell_3 {
    width: 100%;
    background: #F9FAFC;
    overflow: hidden;

    .shell {
      width: 1440px;
      margin: auto;
      padding: 40px 0;

      .s_1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 40px;
        font-weight: 600;
        color: #333333;
      }

      .s_2 {
        white-space: nowrap;
        overflow-x: auto;

        span {
          margin-right: 40px;
          font-size: 16px;
          color: #666666;
          &:hover {
            color: #2878FF;
            cursor: pointer;
          }
        }
      }

      .s_3 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-top: 24px;

        >div {
          width: 19%;
          margin-right: 1%;
          margin-bottom: 16px;
          border-radius: 6px;
          overflow: hidden;
          height: 236px;
          background: #fff;

          .s_3_1 {
            width: 100%;
            height: 153px;
          }

          .s_3_2 {
            padding: 0 14px;
            line-height: 26px;
            margin-top: 16px;
            font-size: 16px;
            color: #333333;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
        }
      }
    }
  }

  .shell_4 {
    width: 100%;
    background: #fff;
    overflow: hidden;

    .shell {
      width: 1440px;
      margin: auto;
      padding: 40px 0;

      .s_1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 10px;
        font-weight: 600;
        color: #333333;
      }

      .s_2 {
        width: 100%;
        margin-top: 40px;
      }
    }
  }

  .shell_5 {
    width: 100%;
    background: #F9FAFC;
    overflow: hidden;

    .shell {
      width: 1440px;
      margin: auto;
      padding: 40px 0;

      .s_1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 40px;
        font-weight: 600;
        color: #333333;
      }

      .s_2 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        >div {
          width: 32%;
          margin-left: 1.33%;
          margin-bottom: 20px;
          border-radius: 6px;
        }

        .s_2_1 {
          background-image: url("../../assets/loginIndexNew/inductrialInjuryPrevention_11.png");
          padding-left: 20px;
          background-position: center;
          background-size: 100% 100%;
          height: 174px;
          box-shadow: 0px 0px 30px 1px rgba(40,120,255,0.06);
          .s_2_1_1 {
            width: 66px;
            height: 26px;
            margin-top: 42px;
            margin-left: 12px;
          }

          .s_2_1_2 {
            margin-top: 10px;
            margin-left: 12px;
            font-size: 18px;
            font-weight: 600;
            color: #333333;
          }

          &:hover {
            transition: all 0.6s;
            cursor: pointer;
            background-image: url("../../assets/loginIndexNew/inductrialInjuryPrevention_3.png");
            .s_2_1_2 {
              color: #fff;
            }
          }
        }

        .s_2_2 {
          background: #fff;

          .s_2_2_1 {
            width: 110px;
            height: 110px;
            display: block;
            margin: auto;
            margin-top: 20px;
          }

          .s_2_2_2 {
            text-align: center;
            font-size: 12px;
            margin-top: 10px;
          }
        }
      }
    }
  }

  .shell_6 {
    width: 100%;
    background: #fff;
    overflow: hidden;

    .shell {
      width: 1440px;
      margin: auto;
      padding: 40px 0;

      .s_1 {
        font-size: 30px;
        text-align: center;
        margin-bottom: 40px;
        font-weight: 600;
      }

      .s_2 {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;

        .s_2_1 {
          width: 18%;
          margin-left: 2%;
          height: 373px;
          margin-bottom: 20px;
          border-radius: 6px;
          background-size: cover;
          background-position: center;
          background-image: url("../../assets/loginIndexNew/inductrialInjuryPrevention_6.png");
          position: relative;

          .s_2_1_1 {
            width: 30px;
            height: 30px;
            position: absolute;
            top: -10px;
            left: 14px;
          }

          .s_2_1_2 {
            font-size: 20px;  
            margin-top: 10px;
            text-align: center;
            color: #333333;
          }
          .cc {
            margin-top: 120px !important;
          }

          .s_2_1_3 {
            width: 100px;
            display: block;
            margin: auto;
            margin-top: 90px;
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 1500px) {
  .inductrialInjuryPrevention .shell_1 {
    height: 250px;
  }
}
</style>
